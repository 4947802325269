import './App.css'
import { useState, useEffect, useRef, useCallback } from 'react'
import axios from 'axios'
import title from './title.png'
import loading from './loading.png'
import errorGif from './error.gif'


function App() {

  if (localStorage.getItem('infinityScroll') === null) {
    localStorage.setItem('infinityScroll', JSON.stringify({ boolean: true }))
  }

  const api = 'https://nobully.zone:3001'
  const [sammakot, setSammakot] = useState([])
  const [filteredPeepos, setFilteredPeepos] = useState([])

  const [search, setSearch] = useState('')
  const [peepoSelect, setPeepoSelect] = useState('')

  const [endOfPage, setEndOfPage] = useState(false)
  const [everythingRendered, setEverythingRendered] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [infinityScrollState, setInfinityScrollState] = useState(JSON.parse(localStorage.getItem('infinityScroll')).boolean)

  const peepoNumRef = useRef(200)



  useEffect(() => {
    setIsLoading(true)
    axios.get(`${api}/sammakot`, { timeout: 60000 })
      .then((res) => {
        setSammakot(res.data)
        setIsLoading(false)
      }).catch((err) => {
        setError(true)
      })
  }, [])


  useEffect(() => {
    setFilteredPeepos(sammakot.filter(peepo => peepo.tags.toLowerCase().includes(search) && peepo.filename.match(peepoSelect)))
  }, [search, sammakot, peepoSelect, infinityScrollState])

  function handleSearchChange(e) {
    setSearch(e.target.value.toLowerCase())
  }

  function handleSelectChange(e) {
    if (e.target.value === 'Staattiset') {
      setPeepoSelect(/^((?!.*\.gif))/i)
    } else if (e.target.value === 'Animaatiot') {
      setPeepoSelect(/.*\.gif/i)
    } else {
      setPeepoSelect('')
    }
  }


  function peepoZoom(e) {
    if (!e.target.className.includes('peepoZoom')) {
      const windowWidth = window.innerWidth // ikkunan leveys
      const imgWidth = e.target.width // alkuperäisen kuvan leveys
      const zoomImgWidth = Number(imgWidth) * 2.5 // suurennetun kuvan leveys
      const elWidth = e.target.parentElement.parentElement.offsetWidth // elementin leveys
      const elLeft = e.target.parentElement.parentElement.offsetLeft // elementistä vasempaan reunaan
      const elSpaceLeft = (elWidth - imgWidth / 2) // paljonko elementissä on tyhjää reunoissa kuvan jälkeen

      if (zoomImgWidth / 2 > elSpaceLeft + elLeft - 20) {
        e.target.className += ' peepoZoom overleft'
      } else if (elSpaceLeft + elLeft + zoomImgWidth / 2 > windowWidth - 5) {
        e.target.className += ' peepoZoom overright'
      } else {
        e.target.className += ' peepoZoom'
      }

    } else {
      e.target.className = 'peepoImg'
    }
  }


  let longClickTimer
  function longClickCopy(e) {
    if (e.target.classList.value.includes('peepoZoom') && !/\b(Android|Windows Phone|BlackBerry)\b/i.test(navigator.userAgent)) {
      longClickTimer = setTimeout(() => {
        navigator.clipboard.writeText(e.target.src)
          .then(e.target.parentElement.parentElement.lastChild.classList += ' copyInfoVisible')
        setTimeout(function () {
          e.target.parentElement.parentElement.lastChild.classList = 'copyInfo'
        }, 700)
      }, 400)
    }
  }
  function longClickCancel(e) {
    clearTimeout(longClickTimer)
  }


  function preventContext(e) {
    if (/\b(Android|Windows Phone|BlackBerry)\b/i.test(navigator.userAgent) && e.target.classList.value.includes('peepoZoom')) {
      e.preventDefault()
      navigator.clipboard.writeText(e.target.src)
        .then(e.target.parentElement.parentElement.lastChild.classList += ' copyInfoVisible')
      setTimeout(function () {
        e.target.parentElement.parentElement.lastChild.classList = 'copyInfo'
      }, 700)
    }
  }


  const handleScroll = useCallback((event) => {
    if (document.documentElement.scrollTop + window.innerHeight + 1000 > document.documentElement.scrollHeight) {
      setEndOfPage(true)
    }
  }, [])

  useEffect(() => {
    if (!everythingRendered) {
      window.addEventListener('scroll', handleScroll)
    }
    if (everythingRendered) {
      window.removeEventListener('scroll', handleScroll)
    }
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line
  }, [filteredPeepos, everythingRendered])

  useEffect(() => {
    if (!everythingRendered && endOfPage && filteredPeepos.length > peepoNumRef.current) {
      peepoNumRef.current += 200
      setEndOfPage(false)
    }
    if (peepoNumRef.current > filteredPeepos.length && !everythingRendered) {
      window.removeEventListener('scroll', handleScroll)
      setEverythingRendered(true)
    }
    // eslint-disable-next-line
  }, [endOfPage])

  useEffect(() => {
    setEverythingRendered(false)
    setEndOfPage(false)
    if (infinityScrollState) {
      peepoNumRef.current = 200
    } else {
      peepoNumRef.current = 1000000
    }
    // eslint-disable-next-line
  }, [filteredPeepos, search])

  function infinityScroll(e) {
    if (!JSON.parse(localStorage.getItem('infinityScroll')).boolean) {
      localStorage.setItem('infinityScroll', JSON.stringify({ boolean: true }))
      setInfinityScrollState(true)
    } else {
      localStorage.setItem('infinityScroll', JSON.stringify({ boolean: false }))
      setInfinityScrollState(false)
    }
  }


  useEffect(() => {
    if (infinityScrollState) {
      peepoNumRef.current = 200
      setEverythingRendered(false)
    } else {
      peepoNumRef.current = 1000000
      setEverythingRendered(true)
    }
  }, [infinityScrollState, filteredPeepos])


  return (
    <div className='Main'>

      {JSON.parse(localStorage.getItem('infinityScroll')).boolean || localStorage.getItem('infinityScroll') === null
        ? <div className='infinityScroll'><input type='checkbox' checked='' id='infinityScroll' onChange={infinityScroll} /><label htmlFor='infinityScroll'>Lataa kaikki</label></div>
        : <div className='infinityScroll'><input type='checkbox' checked='checked' id='infinityScroll' onChange={infinityScroll} /><label htmlFor='infinityScroll'>Lataa kaikki</label></div>
      }

      <div className='peepoHeader'>
        <img src={title} className='peepoTitle' alt='No Bully Zone' /><br />
        <input className='peepoSearch' type='text' name='peepoSearch' onChange={handleSearchChange} autofocus="true"></input>
        <select className='peepoSelect' name='peepoSelect' onChange={handleSelectChange}>
          <option value='Kaikki'>Kaikki</option>
          <option value='Staattiset'>Staattiset</option>
          <option value='Animaatiot'>Animaatiot</option>
        </select>
      </div>

      <div className='peepoMain'>

        {error
          ? <div className='error'><img src={errorGif} alt='virhe' /><br /><h2>Virre hälyntys hälyntys!</h2></div>
          : <></>
        }

        {isLoading && !error
          ? <div className='loading'><img src={loading} alt='peepot lataa' className='loadingImg' /></div>
          :

          filteredPeepos.filter((item, idx) => idx < peepoNumRef.current).map((peepo, index) => (
            <div key={peepo.id} className='peepoDiv' id={peepo.id}>
              <div className='peepoContainer'>
                <img src={'https://nobully.zone/img/' + peepo.filename} alt={peepo.tags} className='peepoImg'
                  onClick={peepoZoom} onMouseDown={longClickCopy} onMouseUp={longClickCancel} onContextMenu={preventContext} />
              </div>
              <span className='peepoTag'>{peepo.tags}</span>
              <span className='copyInfo'>URL kopioitu</span>
            </div>

          ))
        }
      </div>
    </div>
  )


}

export default App
